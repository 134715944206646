import React from "react";
import {BrowserRouter, Switch} from 'react-router-dom';
import PublicRoute from './PublicRoute'
import {getContextPath} from "../utils/UrlBuilder";
import HomePage from "../components/HomePage";
import DownloadPage from "../components/DownloadPage";
import ImagesPage from "../components/ImagesPage";
import ContactPage from "../components/ContactPage";
import MyAppBar from "../components/MyAppBar";
import DemoPage from "../components/DemoPage";
import DocumentationPage from "../components/DocumentationPage";

export default function AppRouter() {

    return (
        <BrowserRouter
            basename={getContextPath()}
        >
            <MyAppBar/>
            <Switch>
                <PublicRoute exact from='/' component={HomePage}/>
                <PublicRoute from='/overview' component={HomePage}/>
                <PublicRoute from='/download' component={DownloadPage}/>
                <PublicRoute from='/documentation' component={DocumentationPage}/>
                <PublicRoute from='/images' component={ImagesPage}/>
                <PublicRoute from='/demo' component={DemoPage}/>
                <PublicRoute from='/contact' component={ContactPage}/>
                <PublicRoute path="*" component={HomePage}/>
            </Switch>
        </BrowserRouter>
    );
}
