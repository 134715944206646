import {Container, Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {MessageBox} from "../utils/MessageBox";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    ContainerClass: {
        marginTop: '20px'
    },
    PaperClass: {
        padding: '30px',
        display: "flex",
        flexDirection: "column",
        minHeight: '20vw'
    },
    Title: {
        marginBottom: '5px'
    },
    TitleRegister: {
        marginTop: '70px',
        marginBottom: '5px'
    },
    Control: {
        marginTop: '20px'
    },
    SendButton: {
        alignSelf: 'center',
        marginTop: '20px',
        minWidth: '30%'
    },
    Contact: {
        marginTop: '10px'
    },
    Progress: {
        marginTop: '20px',
        alignSelf: 'center'
    },
}));

export default function DemoPage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("demo");

    const classes = useStyles();
    // const [state, setState] = useState({email: null, token: null, valid: false, sending: false});
    const [messageBox, setMessageBox] = useState({open: false, severity: 'error', title: '', message: ''});

    return (
        <Container className={classes.ContainerClass} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        <Paper className={classes.PaperClass} elevation={3}>
                            <Typography className={classes.Title} variant="h3" color='secondary'>Demo project</Typography>
                            <Typography className={classes.Contact}>If you want to see and check Databucket, you can get free access to:</Typography>
                            <Typography className={classes.Contact}> - example DEMO project with existing data</Typography>
                            <Typography className={classes.Contact}> - your own empty project (if you want to create all artifacts by yourself)</Typography>
                            <Typography className={classes.Contact}>If you interested in, just send your request to my email address.</Typography>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
            <MessageBox
                config={messageBox}
                onClose={() => setMessageBox({...messageBox, open: false})}
            />
        </Container>
    );
}