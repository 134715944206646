import {Container, Grid, ListItem, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import {MessageBox} from "../utils/MessageBox";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    ContainerClass: {
        marginTop: '20px'
    },
    PaperClass: {
        padding: '30px',
        display: "flex",
        flexDirection: "column",
        minHeight: '30vw'
    },
    Title: {
        marginBottom: '20px'
    },
    Control: {
        marginTop: '20px'
    },
    Image: {
        marginTop: '50px'
    },
    SendButton: {
        alignSelf: 'center',
        marginTop: '20px',
        minWidth: '30%'
    },
    Contact: {
        marginTop: '10px'
    },
    Progress: {
        alignSelf: 'center'
    },
}));

export default function ContactPage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("contact");

    const classes = useStyles();
    const [messageBox, setMessageBox] = useState({open: false, severity: 'error', title: '', message: ''});

    return (
        <Container className={classes.ContainerClass} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Container maxWidth="sm">
                        <Paper className={classes.PaperClass} elevation={3}>
                            <Typography className={classes.Title} variant="h3" color='secondary'>Contact info</Typography>
                            <EmailIcon className={classes.Image} fontSize="large" color='primary'/>
                            <Typography className={classes.Contact}>Krzysztof Słysz</Typography>
                            <Typography className={classes.Contact}>kslysz@gmail.com</Typography>
                            <ListItem className={classes.Contact} button key="Email" component="a" href="mailto:kslysz@gmail.com">Send message</ListItem>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
            <MessageBox
                config={messageBox}
                onClose={() => setMessageBox({...messageBox, open: false})}
            />
        </Container>
    );
}