import {Container, Paper} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    ContainerClass: {
        marginTop: '20px'
    },
    PaperClass: {
        padding: '50px',
        display: "flex",
        flexDirection: "column"
    },
    Title: {
        marginBottom: '5px'
    },
    TitleRegister: {
        marginTop: '70px',
        marginBottom: '5px'
    },
    Control: {
        marginTop: '20px'
    },
    Standard: {
        marginTop: '10px'
    },
    IconButton: {
        alignSelf: 'left'
    }
}));

export default function DownloadPage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("download");

    const classes = useStyles();
    return (
        <Container className={classes.ContainerClass} maxWidth="lg">
            <Paper className={classes.PaperClass} elevation={3}>
                <Typography className={classes.Title} variant="h3" color='secondary'>Download</Typography>
                <Typography className={classes.Standard}>To download Databucket Server, you need to go to Github.</Typography>
                <Typography className={classes.Standard}>Here you have a direct link to the latest release:</Typography>
                <Link rel="noopener noreferrer" href="https://github.com/databucket/databucket-server/releases/latest" target="_blank">https://github.com/databucket/databucket-server/releases/latest</Link>
                <Typography className={classes.Standard}>Find out the databucket-server-3.x.x.jar on the bottom of the page.</Typography>

                <Typography className={classes.Standard}>Description for the installation and configuration Databucket server:</Typography>
                <Link rel="noopener noreferrer" href="https://github.com/databucket/databucket-server/wiki/Installation" target="_blank">https://github.com/databucket/databucket-server/wiki/Installation</Link>
            </Paper>
        </Container>
    );
}