import {Container, Paper} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    ContainerClass: {
        marginTop: '20px'
    },
    PaperClass: {
        padding: '50px',
        display: "flex",
        flexDirection: "column"
    },
    Title: {
        marginBottom: '5px'
    },
    TitleRegister: {
        marginTop: '70px',
        marginBottom: '5px'
    },
    Control: {
        marginTop: '20px'
    },
    Standard: {
        marginTop: '10px'
    },
    IconButton: {
        alignSelf: 'left'
    }
}));

export default function DocumentationPage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("documentation");

    const classes = useStyles();
    return (
        <Container className={classes.ContainerClass} maxWidth="lg">
            <Paper className={classes.PaperClass} elevation={3}>
                <Typography className={classes.Title} variant="h3" color='secondary'>Documentation</Typography>
                <Typography className={classes.Standard}>Everything you need to work with Databucket is described in the Wiki on Github.</Typography>
                <Link rel="noopener noreferrer" href="https://github.com/databucket/databucket-server/wiki" target="_blank">https://github.com/databucket/databucket-server/wiki</Link>

                <Typography className={classes.Standard}>Moreover, here you have a couple of useful links:</Typography>
                <Link target='_blank' href='https://github.com/databucket/databucket-server' color="textSecondary"> * Source code</Link>
                <Link target='_blank' href='https://github.com/databucket/databucket-server/issues/new' color="textSecondary"> * Report a bug, propose a new feature, ask a question...</Link>
                <Link target='_blank' href='https://databucketworkspace.slack.com/archives/C024LBQ4PQU' color="textSecondary"> * Let's talk on Slack</Link>
            </Paper>
        </Container>
    );
}