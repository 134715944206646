import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import ProjectViewImg from "../images/project-view.PNG";
import DataFilterImg from "../images/data-filter.PNG";
import DataDetailsImg from "../images/data-details.PNG";
import DataHistoryImg from "../images/data-history.PNG";
import PropertiesDifferenceImg from "../images/data-properties-difference.PNG";
import SettingsImg from "../images/settings.PNG";
import TaskExecutionImg from "../images/task-execution.PNG";
import SwaggerImg from "../images/swagger.png";
import Container from "@material-ui/core/Container";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    CarouselClass: {
        marginTop: '20px',
        // minHeight: "70vw"
    },
    ContainerClass: {
        // padding: '50px',
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
    },
    HiddenImg: {
        width: '0px',
        height: '0px',
        visibility: 'hidden'
    },
    VisibleImg: {
        visibility: 'visible'
    }
}));

export default function ImagesPage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("images");

    const classes = useStyles();

    const images = [
        {name: "project-view", description: "Project view", img: ProjectViewImg},
        {name: "data-filter", description: "Data filter", img: DataFilterImg},
        {name: "data-details", description: "Data details", img: DataDetailsImg},
        {name: "data-history", description: "Data history", img: DataHistoryImg},
        {name: "properties-difference", description: "Data history", img: PropertiesDifferenceImg},
        {name: "settings", description: "Project settings", img: SettingsImg},
        {name: "task-execution", description: "Task execution", img: TaskExecutionImg},
        {name: "swagger", description: "Swagger", img: SwaggerImg}
    ]

    return (
        <Container maxWidth="lg">
            <Carousel
                className={classes.CarouselClass}
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                animation={false}
            >
                {
                    images.map((item, i) => <Item key={i} item={item} hidden={false}/>)
                }
            </Carousel>

            {/*added to load all images once instead of loading one by one*/}
            {
                images.map((item, i) => <Item key={i} item={item} hidden={true}/>)
            }
        </Container>
    );
}

function Item(props) {
    const classes = useStyles();

    return (
        <Container className={classes.ContainerClass}>
            <img src={props.item.img} alt={props.item.description} className={props.hidden ? classes.HiddenImg: classes.VisibleImg}/>
        </Container>
    )
}