import {AppBar, IconButton, Tab, Tabs, Tooltip} from "@material-ui/core";
import Logo from "../images/logo.png";
import React, {useContext} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import CustomThemeContext from "../context/theme/CustomThemeContext";
import {saveThemeName} from "../utils/ConfigurationStorage";
import SetLightTheme from "@material-ui/icons/Brightness7";
import SetDarkTheme from "@material-ui/icons/Brightness4";
import {makeStyles} from "@material-ui/core/styles";
import {Link, Route} from 'react-router-dom';
import {getAppBarBackgroundColor} from "../utils/Themes";
import MaterialLink from "@material-ui/core/Link";

export default function MyAppBar() {

    const classes = useStyles();
    const tabs = ['overview', 'download', 'documentation', 'images', 'demo', 'contact'];
    const [customThemeName, setCustomThemeName] = useContext(CustomThemeContext);

    const handleChangeTheme = () => {
        let newThemeName;
        if (customThemeName === 'light')
            newThemeName = 'dark';
        else
            newThemeName = 'light';

        setCustomThemeName(newThemeName);
        saveThemeName(newThemeName)
    };

    const getTabsValue = (pathname) => {
        let value = pathname.split("/").pop();
        let tabName = tabs[0];

        if (tabs.includes(value))
            tabName = value;

        return tabName;
    }

    return (
        <AppBar
            position="fixed"
            className={classes.appBar}
            style={{background: getAppBarBackgroundColor()}}
        >
            <Toolbar>
                <MaterialLink component={Link} to='/overview'>
                    <img src={Logo} alt='Databucket'/>
                </MaterialLink>
                <div className={classes.tabs}/>
                <Route
                    path="/"
                    render={({location}) => (
                        <Tabs value={getTabsValue(location.pathname)}>
                            <Tab className={classes.tab} label="Overview" value={tabs[0]} component={Link} to='/overview'/>
                            <Tab className={classes.tab} label="Download" value={tabs[1]} component={Link} to='/download'/>
                            <Tab className={classes.tab} label="Documentation" value={tabs[2]} component={Link} to='/documentation'/>
                            <Tab className={classes.tab} label="Images" value={tabs[3]} component={Link} to='/images'/>
                            <Tab className={classes.tab} label="Demo" value={tabs[4]} component={Link} to='/demo'/>
                            <Tab className={classes.tab} label="Contact" value={tabs[5]} component={Link} to='/contact'/>
                        </Tabs>)}
                />
                <Tooltip title={'DEMO'}>
                    <IconButton
                        target="_blank"
                        href="https://databucket.pl/app"
                        color={'inherit'}
                    >
                        <span className="material-icons">
                            login
                        </span>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Github repository'}>
                    <IconButton
                        target="_blank"
                        href="https://github.com/databucket"
                        color={'inherit'}
                    >
                        <span className="MuiIconButton-label">
                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    d="M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3">
                                </path>
                            </svg>
                        </span>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Toggle dark/light theme'}>
                    <IconButton
                        onClick={handleChangeTheme}
                        color={'inherit'}
                    >
                        {customThemeName === 'light' ? <SetDarkTheme/> : <SetLightTheme/>}
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    tabs: {
        flex: 1
    },
    tab: {
        minWidth: 0,
        paddingInline: '2em'
    }
}));