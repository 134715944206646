const getOrigin = () => {
    const origin = window.location.origin;
    // const origin = 'http://localhost:8080';

    return origin + getContextPath();
}

export const getBaseUrl = (endpoint) => {
    return `${getOrigin()}/api/${endpoint}`;
}

export const getContextPath = () => {
    if (document.getElementById("context-path") != null) {
        const contextPath = document.getElementById("context-path").innerHTML;
        if (contextPath !== "/")
            return contextPath;
    }
    return "";
}