import {Grid, Paper} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    ContainerClass: {
        marginTop: '20px'
    },
    PaperClass: {
        marginTop: '20px',
        padding: '50px'
    },
    Title: {
        marginBottom: '5px'
    },
    FeatureTitle: {
        marginTop: '10px'
    }
}));

export default function HomePage() {

    ReactGA.initialize('UA-86983600-1');
    ReactGA.pageview("home");

    const classes = useStyles();

    return (
        <Container className={classes.ContainerClass} maxWidth="lg">
            <Paper className={classes.PaperClass} elevation={3}>
                <Typography color={'secondary'} variant="h5" display="inline">{'Databucket'}</Typography>
                <Typography display="inline">
                    {" is an Open Source Test Data Management Tool. " +
                    "It delivers features to effectively create and maintain test data and metadata for extensive tests on multiple projects, environments, and various integrated tools at the same time. " +
                    "Databucket stores data in elastic structures which gives a ready approach to maintain constantly changing test data in software development process."}
                </Typography>
            </Paper>

            <Paper className={classes.PaperClass} elevation={3}>
                <Typography className={classes.Title} variant="h4" color='secondary'>Key features</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Multi-tenant Client-server Architecture'}</Typography>
                            <Typography>{' - Easy integration through RESTful API.'}</Typography>
                            <Typography>{' - Isolated data for multiple projects.'}</Typography>
                            <Typography>{' - User access for multiple projects.'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Two-tier permissions'}</Typography>
                            <Typography>{' - Access by user rules.'}</Typography>
                            <Typography>{' - Defined access to artifacts like groups, buckets and views.'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Rich data filtering'}</Typography>
                            <Typography>{' - Searching for properties'}</Typography>
                            <Typography>{' - Standard filtering'}</Typography>
                            <Typography>{' - Extensive filtering'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Data reservation'}</Typography>
                            <Typography>{' - Data access by reservation.'}</Typography>
                            <Typography>{' - Possible hiding of orphaned data.'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Data history'}</Typography>
                            <Typography>{' - Enabling the functionality of collecting information about data changes allows for detailed tracking of creating and modifying collected data.'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Tasks'}</Typography>
                            <Typography>{' - Modifying or deleting performed on multiple datasets.'}</Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.PaperClass} elevation={3}>
                <Typography className={classes.Title} variant="h4" color='secondary'>What to use for?</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Test data'}</Typography>
                            <Typography>{" - Elastic data structure."}</Typography>
                            <Typography>{" - All test data can be sored in one place."}</Typography>
                            <Typography>{" - Ready to use methods to deal with dynamic data."}</Typography>
                            <Typography>{" - Easy work on unique data."}</Typography>
                            <Typography>{" - Team members and automated tests can work on the same data at the same time."}</Typography>
                            <Typography>{' - Ready for tests that requires sophisticated approach.'}</Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container maxWidth="sm">
                            <Typography className={classes.FeatureTitle} color={'primary'} variant="h5">{'Configuration'}</Typography>
                            <Typography>{" - Parameters independent of the code."}</Typography>
                            <Typography>{" - Quick searching and updating."}</Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Paper>

        </Container>
    );
}
