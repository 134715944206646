import React, {useContext} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {getTheme} from './utils/Themes'
import CustomThemeContext from "./context/theme/CustomThemeContext";
import MainContainer from "./components/MainContainer";

export default function App() {

    const [themeName] = useContext(CustomThemeContext);
    return (
        <ThemeProvider theme={getTheme(themeName)}>
            <MainContainer />
        </ThemeProvider>
    );
}